:root {
  --font-size: large;
  --logo-size: 65vh;
}

.App {
  text-align: center;
  min-height: 100vh;
  background-color: black;
  color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  letter-spacing: 0.05rem;
}

.title{
font-size: 4vw;
font-weight: 500;
margin-bottom: 0vh;
}

.Home_row{
  margin-bottom: 2vh !important;
}

#container{
  z-index: 2;
}

#first_row{
  margin-top: 12vh;
}

#logo{
  margin: auto;
  max-width: var(--logo-size);
}

#drop_subtitle{
  margin: auto;
  font-size:  var(--font-size);
  font-style: italic;
  max-width: var(--logo-size);
  margin-bottom: 2vh;
}

#drop_credit{
  margin: auto;
  font-weight: bold;
  font-size: x-large;
  max-width: var(--logo-size);
  margin-bottom: 5vh;
}

#drop_reward{
  margin: auto;
  font-size:  var(--font-size);
  max-width: var(--logo-size);
  margin-bottom: 5vh;
}

#drop_description{
  margin: auto;
  max-width: 100vh;
}

#not_allowed{
  margin: auto;
  max-width: var(--logo-size);
}

#App_row{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vh;
}

#button_row{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5vh 0vh 5vh 0vh;
}

#ash_balance{
  font-size:  var(--font-size);
}

#background_video{
  position: fixed;
  z-index: 1;
  width: 100%;
}

#background{
  background-color: black;
  height: 100%;
}

#mint_button{
  color: white;
  background-color: black !important;
  width: 6em;
  border-width: 1px;
  border-color: white;
  border-radius: 0 !important;
}

#connextion_status{
  /* margin-bottom: -10vh;
  margin-top: 2vh; */
}

#connect_button{
  margin: auto;
  background-color: black !important;
  width: 12em;
  border: 2px solid #FFFFFF !important;
  border-radius: 0 !important;
}

#wl_badge{
  width: 16em !important;
  border-radius: 0 !important;
  font-weight: 400 !important;
}

#claim_badge{
  width: 16em !important;
  border-radius: 0 !important;
  font-weight: 400 !important
}

#connexion_status{
  margin-top: 5vh;
  border-radius: 0 !important;
}

#web3_alert{
  margin: auto;
  border-radius: 0 !important;
  width: 12em;
  color: white;
  background-color: black !important;
  border: 1px solid #FFFFFF !important;
}

.etherscan_link{
  color: rgb(119, 119, 119);
  /* text-decoration: none; */
}

#connexion_info{
  margin-top: 5vh;
  color: rgb(119, 119, 119);
  font-size: 0.7em;
  font-weight: lighter;
}

@media screen and (max-width: 2000px) {
  :root {
    --font-size: medium;
    --logo-size: 75vh;
  }
}


@media screen and (max-width: 1500px) {
  :root {
    --font-size: small;
    --logo-size:85vh;
  }
}